<div class="footer">
    <div id="button"></div>
    <div id="container">
        <div id="cont">
            <div class="footer_center">
                <h3 [routerLink]="['mentions-legales']">Mentions Légales</h3>
            </div>
        </div>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="''">Bienvenue</a>
  
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
  
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">

          <a class="nav-link" [routerLink]="['mediapolis']" (click)="isMenuCollapsed = true" [routerLinkActive]="['active']">Cinéma</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['sport']" (click)="isMenuCollapsed = true" [routerLinkActive]="['active']">Sport</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['map']" (click)="isMenuCollapsed = true" [routerLinkActive]="['active']">Map</a>
        </li>
      </ul>
    </div>
  </nav>
